import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/gastroenteritis"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727 active-trail">
                      <a
                        href="/fakten-uber-durchfall/"
                        className="active-trail"
                      >
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-ursachen-behand"
                  className="block block-menu"
                >
                  <h2>Durchfall - Ursachen &amp; Behandlung</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/fakten-uber-durchfall/" title>
                          Fakten über Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/durchfallmythen/" title>
                          Tipps bei Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/ursachen-von-durchfall/" title>
                          Ursachen von Durchfall
                        </a>
                      </li>
                      <li className="expanded">
                        <a href="/richtig-essen/" title>
                          Gesunde Essgewohnheiten
                        </a>
                        <ul className="menu">
                          {/*<li className="first leaf">
                            <a href="/nahrungsmittelallergie/" title>
                              Nahrungsmittelallergien
                            </a>
                          </li>
                          <li className="leaf">
                            <a href="/lebensmittelvergiftung/" title>
                              Lebensmittelvergiftung
                            </a>
                          </li>*/}
                          <li className="last leaf">
                            <a href="/rezepte/" title>
                              Leckere Rezepte
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded">
                        <a href="/stress-durchfall/" title>
                          Stress und Durchfall
                        </a>
                        <ul className="menu">
                          <li className="first leaf">
                            <a href="/hohe-arbeitsbelastung/" title>
                              Hohe Arbeitsbelastung
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="/prufungssituationen/" title>
                              Prüfungssituationen
                            </a>
                          </li>
                        </ul>
                      </li>
                      {/*<li className="leaf">
                        <a href="/chronischer-durchfall/" title>
                          Chronischer Durchfall
                        </a>
                      </li>*/}
                      <li className="leaf active-trail">
                        <a
                          href="/gastroenteritis/"
                          title
                          className="active-trail active"
                        >
                          Magen-Darm-Grippe
                        </a>
                      </li>
                      {/*<li className="leaf">
                        <a href="/norovirus/" title>
                          Norovirus
                        </a>
                      </li>*/}
                      <li className="last leaf">
                        <a href="/durchfall-wahrend-ihrer-periode/" title>
                          Durchfall während Ihrer Periode
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            Magen-Darm-Grippe
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="flash_container">
                      <div className="field field-name-field-teaser-stuff field-type-text-long field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            <p>
                              <img
                                alt
                                src="/sites/default/files/norovirus_0.jpg"
                                style={{
                                  width: "695px",
                                  height: "286px"
                                }}
                              />
                            </p>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div className="std-site-alternative">
                      <div div className="firstrow">
                        <div className="left">
                          <div className="quote">
                            <span style={{ top: "27px" }}>
                              <h2>
                                <div className="field field-name-field-center-title field-type-text-long field-label-hidden">
                                  <div className="field-items">
                                    <div className="field-item even">
                                      Magen-Darm-Grippe
                                    </div>
                                  </div>
                                </div>{" "}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <p className="subline">
                            Durchfall als Symptom einer Darminfektion. Ein
                            Hinweis auf eine bakterielle oder virale
                            Gastroenteritis – umgangssprachlich als
                            Magen-Darm-Grippe bekannt – ist normalerweise
                            Durchfall in Kombination mit Kopfschmerzen, leichtem
                            Fieber, Übelkeit oder Erbrechen.
                          </p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="secondrow">
                        <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div
                              className="field-item even"
                              property="content:encoded"
                            >
                              <p>
                                Fast jeder Fünfte ist einmal im Jahr von einer
                                viralen oder bakteriellen Gastroenteritis
                                betroffen. In den meisten Fällen verläuft eine
                                Gastroenteritis harmlos und die Symptome klingen
                                schon nach wenigen Tagen wieder ab. Sind jedoch
                                kleine Kinder, ältere oder schon durch eine
                                Krankheit geschwächte Menschen betroffen, sollte
                                ärztlicher Rat eingeholt werden. Auch bei Blut
                                und/oder Schleim im Stuhl oder höherem Fieber
                                sollten Sie einen Arzt hinzuziehen.&nbsp;&nbsp;
                              </p>
                              <h3>
                                Die häufigsten Ursachen einer bakteriellen oder
                                viralen Gastroenteritis
                              </h3>
                              <ul>
                                <li>
                                  Kontakt mit{" "}
                                  <a href="/lebensmittelvergiftung/">
                                    verunreinigten Speisen
                                  </a>
                                  &nbsp; oder Wasser – im Zusammenhang mit
                                  Auslandsaufenthalten spricht man auch von "
                                  <a href="/reisedurchfall-vorbeugen/">
                                    Reisedurchfall
                                  </a>
                                  ".
                                </li>
                                <li>
                                  Virale Infektionen (kommen vor allem im Winter
                                  vor)
                                </li>
                              </ul>
                              <p>
                                Bei einer Gastroenteritis besteht in einigen
                                Fällen hohe Ansteckungsgefahr. Sind Sie
                                betroffen, sollten Sie daher auf eine gute
                                Hygiene achten. Waschen Sie sich vor der
                                Essenszubereitung und nach jedem Toilettengang
                                sehr gründlich die Hände. Wenn die Möglichkeit
                                besteht, sollten Sie nachdem die akute Phase
                                vorüber ist noch weitere 48 Stunden zu Hause
                                bleiben.
                              </p>
                            </div>
                          </div>
                        </div>{" "}
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
